import React, { Component } from "react";
import Link from "gatsby-link";
import Layout from '../components/layout'
import Img from 'gatsby-image'
import specialFormatter from '../utility/special-formatter'

const NavLink = props => {
  if (!props.test) {
    let styles;
    if (props.position == "right") {
      styles = {float: 'right'};
    }
    return <Link to={props.url} className="btn btn-neutral" style={styles}>{props.text}</Link>;
  } else {
    return null;
  }
};

const IndexPage = ({ data, pageContext }) => {
  const { group, index, first, last, pageCount } = pageContext;
  const previousUrl = "blog/" + (index - 1 == 1 ? "" : (index - 1).toString());
  const nextUrl = "blog/" + (index + 1).toString();

  return (
    <Layout>
      <section className="blog-list">
        {group.map(({ node }) => (
          <div key={node.id} className="blogListing">
            <div className="page-title">
              <div className="container">
                <p>
                  {node.publishDate}
                </p>
                <Link to={`/blog/${node.slug}`}><h1>{node.title}</h1></Link>
              </div>
            </div>
            <div className="container article">
              <aside>
                <div className="image">
                  <Img fixed={node.author.image.fixed} />
                </div>
                <a href={`http://www.twitter.com/${node.author.twitter}`}>
                  <p className="author">{node.author.name}</p>
                  <p className="twitter">@{node.author.twitter}</p>
                </a>
              </aside>
              <article>
                <div
                  dangerouslySetInnerHTML={{
                    __html: (node.bodyLegacy ) ? node.bodyLegacy.bodyLegacy : specialFormatter(node.body.childContentfulRichText.html),
                  }}
                />
              </article>
            </div>
          </div>
        ))}

        <div className="container article">
          <article style={{float: 'right', border: 0}}>
            <NavLink test={first} url={previousUrl} text="« Newer posts" />
            <NavLink test={last} url={nextUrl} text="Older posts »" position="right" />
          </article>
        </div>
      </section>

    </Layout>
  );
};

export default IndexPage;
